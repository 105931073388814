'use client'

import React, { useEffect, useState } from 'react'
import { Box, Flex, Spinner } from '@chakra-ui/react'
import Slider from 'react-slick'
import Link from 'next/link'
import { useQuery } from '@tanstack/react-query'
import { getItems_4data } from '@/api'
import { Image } from '@chakra-ui/next-js'

let autoplaySpeed_value = 30000
let autoplay_value = false

// 데스크탑 슬라이더 설정
const Settings_2200 = {
  className: 'center',
  centerMode: true,
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: autoplay_value,
  speed: 500,
  autoplaySpeed: autoplaySpeed_value,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerPadding: '950px', // 적절한 padding 값
  adaptiveHeight: true,
}

const Settings_2000 = {
  className: 'center',
  centerMode: true,
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: autoplay_value,
  speed: 500,
  autoplaySpeed: autoplaySpeed_value,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerPadding: '850px',
  adaptiveHeight: true,
}

const Settings_1800 = {
  className: 'center',
  centerMode: true,
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: autoplay_value,
  speed: 500,
  autoplaySpeed: autoplaySpeed_value,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerPadding: '750px',
  adaptiveHeight: true,
}

// 새로운 설정 추가 (2xl과 xl 사이의 값)
const Settings_1600 = {
  className: 'center',
  centerMode: true,
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: autoplay_value,
  speed: 500,
  autoplaySpeed: autoplaySpeed_value,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerPadding: '650px', // 적절한 padding 값
  adaptiveHeight: true,
}

// 새로운 설정 추가 (xl과 lg 사이의 값)
const Settings_1400 = {
  className: 'center',
  centerMode: true,
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: autoplay_value,
  speed: 500,
  autoplaySpeed: autoplaySpeed_value,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerPadding: '550px', // 적절한 padding 값
  adaptiveHeight: true,
}

const Settings_1200 = {
  className: 'center',
  centerMode: true,
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: autoplay_value,
  speed: 500,
  autoplaySpeed: autoplaySpeed_value,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerPadding: '450px', // 적절한 padding 값
  adaptiveHeight: true,
}

const Settings_1000 = {
  className: 'center',
  centerMode: true,
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: autoplay_value,
  speed: 500,
  autoplaySpeed: autoplaySpeed_value,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerPadding: '350px',
  adaptiveHeight: true,
}

const Settings_800 = {
  className: 'center',
  centerMode: true,
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: autoplay_value,
  speed: 500,
  autoplaySpeed: autoplaySpeed_value,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerPadding: '250px',
  adaptiveHeight: true,
}

const Settings_600 = {
  className: 'center',
  centerMode: true,
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: autoplay_value,
  speed: 500,
  autoplaySpeed: autoplaySpeed_value,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerPadding: '150px', // 적절한 padding 값
  adaptiveHeight: true,
}

const Settings_400 = {
  className: 'center',
  centerMode: true,
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: autoplay_value,
  speed: 500,
  autoplaySpeed: autoplaySpeed_value,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerPadding: '50px', // 적절한 padding 값
  adaptiveHeight: true,
}

const Settings_530 = {
  className: 'center',
  centerMode: true,
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: autoplay_value,
  speed: 500,
  autoplaySpeed: autoplaySpeed_value,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerPadding: '50px', // 적절한 padding 값
  adaptiveHeight: true,
}

// 모바일 슬라이더 설정
const Settings_430 = {
  className: 'center',
  centerMode: true,
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: autoplay_value,
  speed: 500,
  autoplaySpeed: autoplaySpeed_value,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerPadding: '40px', // 모바일에서는 더 큰 여백 적용
  adaptiveHeight: true,
}

const Settings_380 = {
  className: 'center',
  centerMode: true,
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: autoplay_value,
  speed: 500,
  autoplaySpeed: autoplaySpeed_value,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerPadding: '30px', // 적절한 padding 값
  adaptiveHeight: true,
}

// 모바일 슬라이더 설정
const mobileSettings = {
  className: 'center',
  centerMode: true,
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: autoplay_value,
  speed: 500,
  autoplaySpeed: autoplaySpeed_value,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerPadding: '10px', // 모바일에서는 더 큰 여백 적용
  adaptiveHeight: true,
}

export default function CarouselCard() {
  const [sliderSettings, setSliderSettings] = useState(mobileSettings) // 초기값 설정
  const [isLoading, setIsLoading] = useState(false)
  const handleCardClick = () => {
    setIsLoading(true)
  }

  const { data: shopList, isLoading: shopLoading } = useQuery({
    queryKey: ['shopData'],
    queryFn: () => getItems_4data('shops'),
  })

  // 브라우저 리사이즈 이벤트 처리
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth

      if (width >= 2200) {
        // console.log(width, '✅ 2000 이상')
        setSliderSettings(Settings_2200)
      } else if (width >= 2000 && width < 2200) {
        // console.log(width, '✅ 1800 이상')
        setSliderSettings(Settings_2000)
      } else if (width >= 1800 && width < 2000) {
        // console.log(width, '✅ 1800 이상')
        setSliderSettings(Settings_1800)
      } else if (width >= 1600 && width < 1800) {
        // console.log(width, '✅ 1600 이상')
        setSliderSettings(Settings_1600)
      } else if (width >= 1400 && width < 1600) {
        // console.log(width, '✅ 1400 이상')
        setSliderSettings(Settings_1400)
      } else if (width >= 1200 && width < 1400) {
        // console.log(width, '✅ 1200 이상')
        setSliderSettings(Settings_1200)
      } else if (width >= 1000 && width < 1200) {
        // console.log(width, '✅ 1000 이상')
        setSliderSettings(Settings_1000)
      } else if (width >= 800 && width < 1000) {
        // console.log(width, '✅ 800 이상')
        setSliderSettings(Settings_800)
      } else if (width >= 600 && width < 800) {
        // console.log(width, '✅ 600 이상')
        setSliderSettings(Settings_600)
      } else if (width >= 400 && width < 600) {
        // console.log(width, '✅ 400 이상')
        setSliderSettings(Settings_400)
      } else {
        // console.log(width, '✅ base')
        setSliderSettings(mobileSettings)
      }
    }

    handleResize() // 초기 실행
    window.addEventListener('resize', handleResize) // 리사이즈 이벤트 리스너 등록

    // 컴포넌트 언마운트 시 이벤트 리스너 정리
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []) // 의존성 배열이 비어있어 마운트 시 한 번만 실행됨

  return (
    <>
      <Box
        my={20}
        mx={'auto'}>
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        <Slider {...sliderSettings}>
          {/* 😅😅😅😅😅 내일할꺼 이미지만 남기기  */}
          {shopList?.map((card, index) => (
            <div
              data-aos="flip-up"
              data-aos-duration="1000"
              data-aos-delay={index * 200}>
              <Link
                href={`/shops/${card.itemId}`}
                onClick={handleCardClick}>
                <Image
                  key={card.id}
                  border={'1px solid #000000'}
                  src={card.thumbnail}
                  alt={card.title}
                  width={300}
                  height={300}
                  aspectRatio={1}
                  mb={4}
                />
              </Link>
            </div>
          ))}
        </Slider>
        {isLoading && (
          <Flex
            justifyContent="center"
            alignItems="center">
            <Spinner color="orange.500" />
          </Flex>
        )}
      </Box>
    </>
  )
}
