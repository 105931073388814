'use client'

import {
  Box,
  Container,
  Heading,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import Image from 'next/image'

export default function FirstHeroImage() {
  // 모바일 화면 여부
  const isMobile = useBreakpointValue({ base: true, md: false }) // 모바일 여부 체크

  const imageUrl = isMobile
    ? 'https://imagedelivery.net/6ZZEpKdXI04JJCvPE__Mog/c58bdd1b-7435-4398-a4c7-36d44e177000/public'
    : 'https://imagedelivery.net/6ZZEpKdXI04JJCvPE__Mog/c58bdd1b-7435-4398-a4c7-36d44e177000/soop4k'

  return (
    <Box
      margin="0 auto"
      position="relative"
      w="100%"
      height={{ base: '600px', md: '600px', lg: '775px' }}
      overflow="hidden"
      sx={{
        willChange: 'opacity', // 애니메이션 최적화
        backfaceVisibility: 'hidden',
      }}>
      <Image
        src={imageUrl}
        alt="여름숲 메인사진"
        rel="preload"
        fill
        style={{
          objectFit: 'cover',
          willChange: 'transform, opacity', // 성능 최적화
        }}
        priority
        loading="eager"
        sizes="(max-width: 768px) 100vw, (min-width: 769px) 100vw"
      />

      <Container
        position="relative"
        height="100%">
        <Stack
          spacing={8}
          position="absolute"
          top={{ base: '50%', md: '50%' }}
          transform={{
            base: 'translate(0%, -50%)',
            md: 'translate(-50%, -10%)',
          }}
          sx={{
            willChange: 'opacity', // 성능 최적화
          }}>
          <Heading
            color="white"
            fontSize={{ base: '48px', md: '64px' }}
            textAlign={{ base: 'center', md: 'left' }}
            fontWeight="600"
            sx={{
              textShadow: '1px 1px 2px rgba(0, 0, 0, 0.4)', // 텍스트 가독성 향상
            }}>
            Welcome !
            <br />
            여름숲,
          </Heading>
          <Text
            w={{ base: '80%', md: '448px' }}
            color="white"
            mx={{ base: 'auto', md: '0' }}
            textAlign={{ base: 'center', md: 'left' }}
            fontSize={{ base: '14px', md: '16px' }}
            sx={{
              textShadow: '1px 1px 2px rgba(0, 0, 0, 0.4)',
            }}>
            제주의 올레길 16코스. 파란, 주황색 리본을 따라 걷다보면, 파란 창고와
            주황색 부부를 만나실 수 있습니다.
            <br />
            <br />
            나무같은 남편, 그리고 꽃같은 아내. 원예학을 전공한 부부가 가꾸어낸
            다채로운 초록들. 식물하는 부부의 공간에 오신 것을 환영합니다.
          </Text>
        </Stack>
      </Container>
    </Box>
  )
}
