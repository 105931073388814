'use client'

import { getImages } from '@/api'
import {
  Box,
  Center,
  Grid,
  GridItem,
  Image,
  Spinner,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

interface ImageMap {
  [position: number]: string
}

export default function InstagramPictures() {
  const [imagesMap, setImagesMap] = useState<ImageMap>({})
  const isMobile = useBreakpointValue({ base: true, md: false }) // 모바일 여부 체크

  const { data, isLoading, error } = useQuery<any>({
    queryKey: ['instagramImages'],
    queryFn: () => getImages(),
  })

  // useEffect를 사용하여 데이터가 변경될 때마다 imagesMap 업데이트
  useEffect(() => {
    if (data) {
      const map: ImageMap = {}
      Object.entries(data).forEach(([key, value]) => {
        const position = parseInt(key)
        if (!isNaN(position) && typeof value === 'string') {
          map[position] = value
        }
      })
      setImagesMap(map)
    }
  }, [data])

  // 로딩 상태 처리
  if (isLoading) {
    return (
      <Center py={12}>
        <Spinner size="xl" />
      </Center>
    )
  }

  // 에러 상태 처리
  if (error) {
    return (
      <Center py={12}>
        <Text color="red.500">이미지를 불러오는 데 실패했습니다.</Text>
      </Center>
    )
  }

  return (
    <>
      <Box
        py={12}
        maxWidth="1440px"
        margin="0 auto"
        display="flex"
        justifyContent="center"
        alignItems="center">
        {!isMobile ? (
          <Grid
            h="auto"
            mb={60}
            templateColumns="repeat(4, 1fr)" // 4개의 열로 구성
            templateRows="repeat(2, 1fr) 276px" // 첫 2개의 행은 균등한 높이, 마지막 행은 고정된 276px 높이
            gap={10} // 각 그리드 아이템 간의 간격
            width="80%" // 전체 그리드의 너비
          >
            {/* 1번 사진, 2x2 크기 */}
            <GridItem
              colSpan={2}
              rowSpan={2}>
              <div
                data-aos="flip-left"
                data-aos-duration="1000"
                style={{
                  width: '100%', // GridItem의 너비에 맞추기
                  height: '100%', // GridItem의 높이에 맞추기
                  display: 'flex', // Flexbox 사용
                  alignItems: 'center', // 수직 정렬
                  justifyContent: 'center', // 수평 정렬
                }}>
                <Image
                  src={imagesMap[1]}
                  alt={`여름숲_${1}`}
                  loading="lazy"
                  w="100%"
                  h="100%"
                  objectFit="cover"
                />
              </div>
            </GridItem>

            {/* 2번 사진 */}
            <GridItem>
              <div
                data-aos="flip-left"
                style={{
                  width: '100%', // GridItem의 너비에 맞추기
                  height: '100%', // GridItem의 높이에 맞추기
                  display: 'flex', // Flexbox 사용
                  alignItems: 'center', // 수직 정렬
                  justifyContent: 'center', // 수평 정렬
                }}>
                <Image
                  src={imagesMap[2]}
                  alt={`여름숲_${2}`}
                  loading="lazy"
                  w="100%"
                  h="100%"
                  objectFit="cover"
                />
              </div>
            </GridItem>

            {/* 3번 사진 */}
            <GridItem>
              <div
                data-aos="flip-left"
                style={{
                  width: '100%', // GridItem의 너비에 맞추기
                  height: '100%', // GridItem의 높이에 맞추기
                  display: 'flex', // Flexbox 사용
                  alignItems: 'center', // 수직 정렬
                  justifyContent: 'center', // 수평 정렬
                }}>
                <Image
                  src={imagesMap[3]}
                  alt={`여름숲_${3}`}
                  loading="lazy"
                  w="100%"
                  h="100%"
                  objectFit="cover"
                />
              </div>
            </GridItem>

            {/* 4번 사진 */}
            <GridItem>
              <div
                data-aos="flip-left"
                style={{
                  width: '100%', // GridItem의 너비에 맞추기
                  height: '100%', // GridItem의 높이에 맞추기
                  display: 'flex', // Flexbox 사용
                  alignItems: 'center', // 수직 정렬
                  justifyContent: 'center', // 수평 정렬
                }}>
                <Image
                  src={imagesMap[4]}
                  alt={`여름숲_${4}`}
                  loading="lazy"
                  w="100%"
                  h="100%"
                  objectFit="cover"
                />
              </div>
            </GridItem>

            {/* 5번 사진 */}
            <GridItem>
              <div
                data-aos="flip-left"
                style={{
                  width: '100%', // GridItem의 너비에 맞추기
                  height: '100%', // GridItem의 높이에 맞추기
                  display: 'flex', // Flexbox 사용
                  alignItems: 'center', // 수직 정렬
                  justifyContent: 'center', // 수평 정렬
                }}>
                <Image
                  src={imagesMap[5]}
                  alt={`여름숲_${5}`}
                  loading="lazy"
                  w="100%"
                  h="100%"
                  objectFit="cover"
                />
              </div>
            </GridItem>

            {/* 6번 사진 */}
            <GridItem>
              <div
                data-aos="flip-left"
                style={{
                  width: '100%', // GridItem의 너비에 맞추기
                  height: '100%', // GridItem의 높이에 맞추기
                  display: 'flex', // Flexbox 사용
                  alignItems: 'center', // 수직 정렬
                  justifyContent: 'center', // 수평 정렬
                }}>
                <Image
                  src={imagesMap[6]}
                  alt={`여름숲_${6}`}
                  loading="lazy"
                  w="100%"
                  h="100%"
                  objectFit="cover"
                />
              </div>
            </GridItem>

            {/* 7번 사진 */}
            <GridItem>
              <div
                data-aos="flip-left"
                style={{
                  width: '100%', // GridItem의 너비에 맞추기
                  height: '100%', // GridItem의 높이에 맞추기
                  display: 'flex', // Flexbox 사용
                  alignItems: 'center', // 수직 정렬
                  justifyContent: 'center', // 수평 정렬
                }}>
                <Image
                  src={imagesMap[7]}
                  alt={`여름숲_${7}`}
                  loading="lazy"
                  w="100%"
                  h="100%"
                  objectFit="cover"
                />
              </div>
            </GridItem>

            {/* 8번 사진, 2열 크기 */}
            <GridItem colSpan={2}>
              <div
                data-aos="flip-right"
                data-aos-duration="1000"
                style={{
                  width: '100%', // GridItem의 너비에 맞추기
                  height: '100%', // GridItem의 높이에 맞추기
                  display: 'flex', // Flexbox 사용
                  alignItems: 'center', // 수직 정렬
                  justifyContent: 'center', // 수평 정렬
                }}>
                <Image
                  src={imagesMap[8]}
                  alt={`여름숲_${8}`}
                  loading="lazy"
                  w="100%"
                  h="100%"
                  objectFit="cover"
                />
              </div>
            </GridItem>
          </Grid>
        ) : (
          <>
            <Grid
              p={4}
              mb={60}
              templateColumns="repeat(2, 1fr)" // 2개의 열로 구성
              templateRows="repeat(4, 1fr)" // 4개의 행으로 구성
              gap={4} // 각 그리드 아이템 간의 간격
              width="100%" // 전체 그리드의 너비
              h="100%">
              {/* 모바일 1번 사진 */}
              <GridItem
                colSpan={1}
                rowSpan={2}>
                <Image
                  src={imagesMap[1]}
                  alt={`여름숲_${1}`}
                  loading="lazy"
                  w="100%"
                  h="100%"
                  objectFit="cover"
                />
              </GridItem>

              {/* 모바일 2번 사진 */}
              <GridItem
                colSpan={1}
                rowSpan={1}>
                <Image
                  src={imagesMap[2]}
                  alt={`여름숲_${2}`}
                  loading="lazy"
                  w="100%"
                  h="100%"
                  objectFit="cover"
                />
              </GridItem>

              {/* 모바일 3번 사진 */}
              <GridItem
                colSpan={1}
                rowSpan={1}>
                <Image
                  src={imagesMap[3]}
                  alt={`여름숲_${3}`}
                  loading="lazy"
                  w="100%"
                  h="100%"
                  objectFit="cover"
                />
              </GridItem>

              {/* 모바일 4번 사진 */}
              <GridItem
                colSpan={1}
                rowSpan={1}>
                <Image
                  src={imagesMap[4]}
                  alt={`여름숲_${4}`}
                  loading="lazy"
                  w="100%"
                  h="100%"
                  objectFit="cover"
                />
              </GridItem>

              {/* 모바일 5번 사진 */}
              <GridItem
                colSpan={1}
                rowSpan={2}>
                <Image
                  src={imagesMap[5]}
                  alt={`여름숲_${5}`}
                  loading="lazy"
                  w="100%"
                  h="100%"
                  objectFit="cover"
                />
              </GridItem>

              {/* 모바일 6번 사진 */}
              <GridItem
                colSpan={1}
                rowSpan={1}>
                <Image
                  src={imagesMap[6]}
                  alt={`여름숲_${6}`}
                  loading="lazy"
                  w="100%"
                  h="100%"
                  objectFit="cover"
                />
              </GridItem>
            </Grid>
          </>
        )}
      </Box>
    </>
  )
}
