import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore'
import { dbService } from './FBase'

// 🗺 사용자 Level 정보 가져오기!
export const getUser = async (userUID: any) => {
  const user_uid = userUID.queryKey[1]
  try {
    const q = await query(
      collection(dbService, 'User'),
      where('uid', '==', user_uid)
    )

    const querySnapshot = await getDocs(q)
    const memberDoc = querySnapshot.docs[0]
    if (memberDoc) {
      const memberData = memberDoc.data()
      return {
        ...memberData,
      }
    }
  } catch (error) {
    console.log('error:', error)
  }
}

// 🗺 컬렉션에 맞는 데이터 가져오기
export const getItems = async (collectionType: string, itemId: any) => {
  const item_id = itemId
  const q = query(
    collection(dbService, collectionType), // collectionType을 사용하여 동적 컬렉션 선택
    where('itemId', '==', item_id)
  )

  const querySnapshot = await getDocs(q)
  const itemDoc = querySnapshot.docs[0]

  if (itemDoc) {
    const itemData = itemDoc.data()

    return {
      ...itemData,
    }
  } else {
    throw new Error('해당 블로그를 찾을 수 없습니다.')
  }
}

// 🗺 컬렉션에 맞는 데이터 리스트 가져오기
export const getItemsList = async (collectionType: string) => {
  const q = query(
    collection(dbService, collectionType),
    orderBy('createdAt', 'desc')
  )
  const querySnapshot = await getDocs(q)
  const items = querySnapshot.docs.map((doc) => ({
    ...doc.data(),
  }))

  return items
}

// 🗺 컬렉션에 맞는 데이터 4개만 가져오기
export const getItems_4data = async (collectionType: string) => {
  const q = query(
    collection(dbService, collectionType),
    where('visibility', '==', 'public'),
    orderBy('createdAt', 'desc'),
    limit(4)
  )
  const querySnapshot = await getDocs(q)
  const items = querySnapshot.docs.map((doc) => ({
    ...doc.data(),
  }))

  return items
}

// 🗺 컬렉션에 맞는 데이터 n개만 가져오기
export const getItems_data = async (collectionType: string, count: number) => {
  const q = await query(
    collection(dbService, collectionType),
    where('visibility', '==', 'public'),
    orderBy('createdAt', 'desc'),
    limit(count)
  )
  const querySnapshot = await getDocs(q)
  const items = querySnapshot.docs.map((doc) => ({
    ...doc.data(),
  }))

  return items
}

// 이전 페이지 데이터 가져오기
export const prevGetData = async (collectionType: string, currentData: any) => {
  const q = await query(
    collection(dbService, collectionType),
    orderBy('createdAt', 'desc'),
    where('createdAt', '<', currentData.createdAt),
    limit(1)
  )

  const prevData = await getDocs(q)

  return prevData.docs.length ? prevData.docs[0].data() : null
}
// 이후 페이지 데이터 가져오기
export const nextGetData = async (collectionType: string, currentData: any) => {
  const q = await query(
    collection(dbService, collectionType),
    orderBy('createdAt', 'asc'),
    where('createdAt', '>', currentData.createdAt),
    limit(1)
  )

  const nextData = await getDocs(q)

  return nextData.docs.length ? nextData.docs[0].data() : null
}

export interface ImageMap {
  [position: number]: string
}

export interface ImageData {
  id: string
  url: string
  createdAt: any // Firebase Timestamp
}

// 🗺 InstagramImages 컬렉션의 이미지 가져오기
export const getImages = async (): Promise<ImageMap> => {
  const imagesCollection = collection(dbService, 'InstagramImages')
  const q = query(imagesCollection, orderBy('createdAt', 'desc'))
  const querySnapshot = await getDocs(q)

  const imagesMap: ImageMap = {}

  querySnapshot.forEach((docSnapshot) => {
    const data = docSnapshot.data() as ImageData
    const position = parseInt(docSnapshot.id)
    if (!isNaN(position) && data.url) {
      imagesMap[position] = data.url
    }
  })

  return imagesMap
}
