'use client'
import { Box, Text, keyframes } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'

export default function LongViewAll({
  contentText,
  fontSize,
  totalHeight,
  marginTop,
  backgroundColor,
  font_color,
}: {
  contentText: string
  fontSize: string
  totalHeight: string
  marginTop: number
  backgroundColor: string
  font_color: string
}) {
  const textRef = useRef<any>(null)
  const [textWidth, setTextWidth] = useState(0)

  useEffect(() => {
    if (textRef.current) {
      setTextWidth(textRef.current.scrollWidth)
    }
  }, [])

  const marquee = keyframes`
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-${textWidth}px);
    }
  `

  return (
    <Box
      mt={marginTop}
      overflow="hidden"
      height={totalHeight}
      backgroundColor={backgroundColor}
      color="white"
      display="flex"
      alignItems="center">
      <Box
        display="flex"
        animation={`${marquee} ${textWidth / 50}s linear infinite`} // 50px/s 속도로 애니메이션
        style={{ width: `${textWidth * 2}px` }} // 텍스트 길이의 2배만큼 너비 설정
      >
        <Text
          ref={textRef}
          color={font_color}
          fontSize={fontSize}
          fontWeight="900"
          whiteSpace="nowrap"
          display="inline-block">
          {contentText.repeat(10)}
        </Text>
        <Text
          fontSize={fontSize}
          color={font_color}
          fontWeight="900"
          whiteSpace="nowrap"
          display="inline-block">
          {contentText.repeat(10)}
        </Text>
      </Box>
    </Box>
  )
}
