'use client'

import { Box, Flex, Image, Spinner, Text } from '@chakra-ui/react'
import Link from 'next/link'
import { useState } from 'react'

export default function HomeMegazinCard({
  index,
  rotation,
  y_value,
  imageSrc,
  imageTitle,
  imageDescription,
  imageLink,
}: {
  index: number
  rotation: number
  y_value: number
  imageSrc: string
  imageTitle: string
  imageDescription: string
  imageLink: string
}) {
  const [isLoading, setIsLoading] = useState(false)
  const handleCardClick = () => {
    setIsLoading(true)
  }

  return (
    <div
      data-aos="fade-left"
      data-aos-duration="1000"
      data-aos-delay={index * 200}>
      <Box
        position="relative"
        as={Link}
        backgroundColor={'white'}
        onClick={handleCardClick}
        cursor={isLoading ? 'wait' : 'pointer'}
        href={imageLink}
        justifyContent={'center'}
        alignItems={'center'}
        display={'flex'}
        flexDirection={'column'}
        maxW="463px"
        maxH="536px"
        rounded={16}
        border={'1px solid'}
        // boxShadow="2xl" // 그림자 효과 추가
        overflow="hidden" // 이미지가 카드 밖으로 넘치지 않도록 처리
        transform={`rotate(${rotation}deg) translateY(${y_value}px)`} // rotation과 y_value 값을 이용해 카드 회전 및 Y축 이동
        transition="transform 0.3s ease" // 회전 및 Y축 애니메이션
        _hover={{
          zIndex: 1,
          textDecoration: 'underline',
          transform: `rotate(${
            rotation > 0 ? -rotation + 15 : rotation + 15
          }deg) translateY(${y_value}px)`,
        }} // 마우스 오버 시 그림자 효과 및 회전
      >
        <Image
          // hover시 이미지 축소 효과
          // 기본 상태에서도 스무스하게 전환
          transition="transform 0.4s ease"
          _hover={{
            transform: 'scale(0.95)',
          }}
          pt={4}
          px={4}
          roundedTop={'lg'}
          src={imageSrc}
          alt={`여름숲1`}
          maxW={{ base: 300, md: 360 }}
          aspectRatio={1}
          objectFit="cover"
          loading="lazy"
        />

        <Box>
          <Text
            mt={8}
            fontSize="14px"
            color="main-textColor"
            fontWeight="bold">
            {imageTitle}
            {isLoading && (
              <Flex
                justifyContent="center"
                alignItems="center"
                mt={4}>
                <Spinner color="orange.500" />
              </Flex>
            )}
          </Text>
          <Text
            maxW={220}
            fontSize={11}
            py={2}
            mb={8}>
            {imageDescription}
          </Text>
        </Box>
      </Box>
    </div>
  )
}
