import { initializeApp } from 'firebase/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'

import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyBoHyMNCelJJCSXGU0VNHN_HIw1KeX-TxQ',
  authDomain: 'yeoreumsoop.firebaseapp.com',
  projectId: 'yeoreumsoop',
  storageBucket: 'yeoreumsoop.appspot.com',
  messagingSenderId: '373734463113',
  appId: '1:373734463113:web:24da609fd0bedb9bb0d858',
  measurementId: 'G-J2FGLM2LP5',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

export const authService = getAuth(app)
export const dbService = getFirestore()
export const storageService = getStorage()
