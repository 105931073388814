'use client'

import { Box, Flex, Text, VStack, useBreakpointValue } from '@chakra-ui/react'

// 메인페이지 푸터 바로 위, 비스듬히 사선으로 나타나는 이미지 컴포넌트
export default function HomeImageHalf() {
  const isMobile = useBreakpointValue({ base: true, md: false }) // 모바일 여부 체크

  return (
    <>
      <VStack
        zIndex={3}
        w={'100%'}
        h={'376px'}
        justifyContent={'center'}
        alignItems={'center'}
        textAlign={'center'}
        padding="0 20px"
        position="relative" // 배경 이미지 효과를 위한 상대적 위치 지정
        overflow="hidden" // 자식 요소가 부모 요소를 넘치지 않도록 설정
      >
        <Box
          position="absolute" // 배경 효과를 위해 절대 위치 지정
          top={0}
          left={0}
          right={0}
          bottom={0}
          zIndex={-1} // 텍스트 위에 나타나지 않도록 설정
          backgroundImage={`
            linear-gradient(0deg, #ecf5f7 1px, transparent 0)
          `}
          backgroundSize="10px 10px, 10px 10px" // 그리드 크기 설정
        />
        <Box
          position="absolute" // 배경 효과를 위해 절대 위치 지정
          top={0}
          left={0}
          right={0}
          bottom={0}
          zIndex={-1} // 텍스트 위에 나타나지 않도록 설정
          backgroundImage={`
           linear-gradient(90deg, #ecf5f7 1px, transparent 0)
         `}
          backgroundSize="10px 10px, 10px 10px" // 그리드 크기 설정
        ></Box>
        <Box width="448px">
          <Text
            fontWeight={600}
            fontSize={28}>
            여름숲 : 식물하는 부부의 공간
          </Text>
          <Text
            mt={4}
            fontSize={14}>
            우리는 초록을 만나고, 소개하고, 디자인하고, 판매합니다. 여행하듯
            열정적이고 자유롭게 다양한 경험을 가진 우리는 무엇보다 우리다운,
            설레는 일들을 합니다.
          </Text>
        </Box>
      </VStack>
    </>
  )
}
